import React from "react"
import { graphql } from "gatsby"
import { SEO } from "../components/Seo"
import { LocaleContext } from "../components/Layout/Layout"
import {
  BlogPostWrapper,
  BlogPostDetails,
  BlogPostDetail,
  ReadingDetailsContainer,
  ReadingDetails,
  AuthorPicture,
  Author,
  BlogPostHeading,
  BlogPostBody,
} from "./blogpost-styles"

import { Image, Text, Video } from "../slices"
import { PortView } from "../slices/slices-styles"

const BlogPost = ({
  data: { prismicBlogPost },
  location,
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)

  const i18n = lang.i18n[lang.locale]

  const {
    data: { meta_title, meta_description, author, title, body, reading_time },
    first_publication_date,
  } = prismicBlogPost

  const dateConfig = {
    year: "numeric",
    month: "short",
    day: "numeric",
  }
  const date = new Date(first_publication_date)

  const authorData = author.document[0].data

  const slices = body.map((slice, index) => {
    switch (slice.slice_type) {
      // These are the API IDs of the slices
      case "text":
        return <Text key={index} data={slice} />
      case "image":
        return <Image key={index} data={slice} />
      case "video":
        return <Video key={index} data={slice} />
      default:
        return null
    }
  })
  return (
    <>
      <SEO
        title={`${meta_title} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        desc={meta_description}
        node={prismicBlogPost}
        article
        author={author.document[0].data}
      />
      <BlogPostWrapper>
        <PortView>
          <BlogPostHeading
            dangerouslySetInnerHTML={{
              __html: title && title.html,
            }}
          />
          <BlogPostDetails>
            <AuthorPicture
              fixed={authorData.profile_picture.localFile.childImageSharp.fixed}
            />
            <ReadingDetailsContainer>
              <Author>
                {authorData.first_name && authorData.first_name}{" "}
                {authorData.last_name && authorData.last_name}
              </Author>
              <ReadingDetails>
                <BlogPostDetail>
                  {date && Intl.DateTimeFormat(locale, dateConfig).format(date)}
                </BlogPostDetail>
                <BlogPostDetail>
                  · {reading_time && reading_time}
                </BlogPostDetail>
              </ReadingDetails>
            </ReadingDetailsContainer>
          </BlogPostDetails>
        </PortView>
        <BlogPostBody>{slices && slices}</BlogPostBody>
      </BlogPostWrapper>
    </>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    prismicBlogPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title
        meta_description
        title {
          html
          text
        }
        reading_time
        author {
          document {
            data {
              first_name
              last_name
              about_person_short
              profile_picture {
                localFile {
                  childImageSharp {
                    fixed(height: 60, width: 60) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicBlogPostBodyText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBlogPostBodyVideo {
            slice_type
            primary {
              video_title
              video_url
            }
          }
        }
      }
    }
  }
`
