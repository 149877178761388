import styled from "styled-components"
import Img from "gatsby-image"

export const BlogPostWrapper = styled.div`
  margin-top: 150px;
  width: 100vw;
  min-height: 100vh;

  & > h1 {
    text-align: center;
  }

  @media (min-width: 800px) {
    margin-top: 200px;
  }
`
export const BlogPostDetails = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;

  @media (min-width: 800px) {
    margin: 20px 0;
  }
`
export const ReadingDetailsContainer = styled.div`
  margin-left: 20px;
`
export const AuthorPicture = styled(Img)`
  width: 20%;
  border-radius: 50%;
`
export const Author = styled.p`
  margin: 5px 0;
  color: black;
  font-size: 1rem;
  line-height: 1;
  color: #343434;
  font-weight: bold;
`
export const ReadingDetails = styled.div`
  display: flex;
`

export const BlogPostDetail = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  color: black;
  font-size: 1rem;
  line-height: 1;
  color: grey;
`

export const BlogPostHeading = styled.div`
  & > h1 {
    margin: 0 20px;
    color: black;
    font-size: 1.5rem;
    line-height: 1.68;
    letter-spacing: 12.5px;
    color: #343434;
    text-transform: uppercase;

    @media (min-width: 800px) {
      margin: 0;
      font-size: 2.5em;
      line-height: 1.5;
      font-weight: bold;
      font-stretch: condensed;
      font-style: normal;
      letter-spacing: 18px;
      text-align: left;
      color: #343434;
    }
  }
`

export const BlogPostBody = styled.div`
  & > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0px;
    text-transform: uppercase;
  }
  & > * + * {
    margin-top: 40px;
    margin-bottom: 40px;

    @media (min-width: 800px) {
      margin-top: 150px;
      margin-bottom: 100px;
    }
  }
  @media (min-width: 800px) {
    margin-top: 40px;
  }
`
